
import { Component, Vue } from "vue-property-decorator";
import LayoutWithNavAndFooter from "@/layout/LayoutWithNavAndFooter.vue";
import Title from "@/components/Title.vue";

@Component({
  components: {
    LayoutWithNavAndFooter,
    Title
  }
})
export default class Faq extends Vue {
  questions = [
    {
      title: this.$t("faq.1.title"),
      answer: [this.$t("faq.1.answer")]
    },
    {
      title: this.$t("faq.2.title"),
      answer: [this.$t("faq.2.answer")]
    },
    {
      title: this.$t("faq.3.title"),
      answer: [this.$t("faq.3.answer")]
    }
  ];
}
